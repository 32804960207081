import { MainScreen } from './screens/main';
import { ContentBuilderBlock } from "./components/content-builder";

export const App = (): React.ReactElement => {
    return (
      <ContentBuilderBlock>
          <MainScreen />
      </ContentBuilderBlock>
    );
}
