export const assetsList: {
    [key: string]: {
        [key: string]: {
            name: string,
            modules: string[],
        }
    }
} = {
    appemail: {
        fullWidthBlock: {
            name: "full-width-block",
            modules: ["RTE"]
            
        },
        heroFullWidthImage: {
            name: "hero-full-width-image",
            modules: []
        },
        heroGeneral: {
            name: "hero-general",
            modules: ["title", "subtitle"]
        },
        iconLeft: {
            name: "icon-left",
            modules: ["RTE"]

        },
        iconRight: {
            name: "icon-right",
            modules: ["RTE"]
        }
    },
    appfax: {
        heroGeneral: {
            name: "hero-general",
            modules: ["title"]
        },
    },
    newsletter: {
        heroFullWidthImage: {
            name: "hero-full-width-image",
            modules: []
        },
        heroGeneral: {
            name: "hero-general",
            modules: ["title", "subtitle"]
        },
        fullWidthBlock: {
            name: "full-width-block",
            modules: ["RTE"]
        },
        iconRight: {
            name: "icon-right",
            modules: ["RTE"]  
        },
        iconLeft: {
            name: "icon-left",
            modules: ["RTE"]
        }
    }
}