import React from 'react';
import { html } from "@codemirror/lang-html";
import { EditorView, basicSetup } from "codemirror";

interface ICodeEditorProps {
    value: string;
    isEditable?: boolean;
}

export const CodeEditor = ({ value, isEditable }: ICodeEditorProps) => {

    React.useEffect(() => {
        new EditorView({
            doc: value,
            extensions: [
                EditorView.contentAttributes.of({ 
                    //@ts-ignore
                    contenteditable: Boolean(isEditable) 
                }),
                html(),
                basicSetup
            ],
            parent: document.querySelector("#__code_editor__") || undefined
        });
    }, []);

    return (
        <div id="__code_editor__" style={{ width: "100%" }} />
    )
}