import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext} from '@mui/lab';

interface ITab {
    label: string;
    component: React.ReactElement;
    isHidden?: boolean;
}

type Tabs = (props: { items: Array<ITab> }) => React.ReactElement;

export const Tabs: Tabs = ({ items }) => {

  const [value, setValue] = React.useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body2', height: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', typography: 'body2' }}>
          <TabList onChange={handleChange}>
          {items.filter((item) => item.isHidden !== true).map((item, i) => <Tab sx={{ fontSize: 12 }} label={item.label} value={i.toString()} />)}
          </TabList>
        </Box>
          {items.filter((item) => item.isHidden !== true).map((item, i) => <TabPanel sx={{ height: '94%', paddingLeft: 0, paddingRight: 0 }} value={i.toString()}>{item.component}</TabPanel>)}
      </TabContext>
    </Box>
  );
}