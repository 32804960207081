import React from 'react';
import { Box, Menu, TextField, Button as ButtonMUI  } from '@mui/material';
import { EditorState, Modifier } from 'draft-js'

interface IBulletpointProps {
    onClose: () => void;
    editorState: EditorState;
    anchor: HTMLElement | null;
    setEditorState: (value: EditorState) => void;
}

export const Bulletpoint = ({ onClose, anchor, editorState, setEditorState }: IBulletpointProps) => {
    const [text, setText] = React.useState<string>("");

    const insertString = (): void => {
        const appendStr = `|bulletpoint text=='${text}'|`
        if (editorState !== null) {
            const newContent = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                appendStr,
            );

            setEditorState(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
              ));

            setText("")
        }
    }

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchor)}
                onClose={onClose}
                variant="menu"
                disableAutoFocusItem={false}
            >
                <Box maxHeight={300} pr={2}>
                    
                    <TextField
                        fullWidth
                        size="small"
                        label="List item"
                        defaultValue={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{marginLeft: 1}}
                        />
                    
                    <ButtonMUI
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={insertString}
                        disabled={text === ""}
                        sx={{marginTop: 1, marginLeft: 1}}
                    >
                        Add
                </ButtonMUI>
                    
                </Box>
            </Menu>
        </React.Fragment>
    )
}