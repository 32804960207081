import React from 'react';
import Axios, {AxiosRequestConfig, CancelTokenSource} from 'axios';

interface IFetchState {
    cancel: () => void;
    isLoading: boolean;
}

interface IFetcherProps {
    config: AxiosRequestConfig,
    onSuccess: (data: any) => void;
    onError: (error: string) => void;
}

const defaultCancel = () => undefined;

export const useFetcher = ({ config, onSuccess, onError }: IFetcherProps, requestAtStart: boolean = true) => {
    const [state, updateState] = React.useState<IFetchState>({
        isLoading: false,
        cancel: defaultCancel
    });

    const fetch = (data?: any) => {
        state.cancel();
        const cancelSource = Axios.CancelToken.source();

        updateState({
            isLoading: true,
            cancel: () => cancelSource.cancel()
        });

        Axios.request({data, ...config, cancelToken: cancelSource.token})
             .then(response => onSuccess(response.data))
             .catch((ex) => {
                 if (!Axios.isCancel(ex)) onError(ex.message);
             })
             .finally(() => {
                updateState({
                    isLoading: false,
                    cancel: defaultCancel
                });
             });
    }

    React.useEffect(() => {
        if(requestAtStart === true) {
            fetch()
        }

        return () => {
            state.cancel();
        };
    }, []);


    return {
        fetch,
        ...state
    }
}