import React from 'react';
import { Box, Menu, TextField, Button as ButtonMUI } from '@mui/material';
import { EditorState, Modifier } from 'draft-js'
import { useFetcher } from '../helpers/fetch';

interface IButtonProps {
    onClose: () => void;
    editorState: EditorState;
    anchor: HTMLElement | null;
    setEditorState: (value: EditorState) => void;
}

export const Button = ({ onClose, anchor, editorState, setEditorState }: IButtonProps) => {
    const [link, setLink] = React.useState<string>("");
    const [text, setText] = React.useState<string>("");
    const [idUrl, setIdUrl] = React.useState<string>("")
    const [token, setToken] = React.useState("")

    const insertString = () => {
        const appendStr = `|button link=='${link}' text=='${text}'|`
        if (editorState !== null && link !== "") {
            const newContent = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                appendStr,
            );
    
            setEditorState(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            ));
            
            console.log(link, text)
            setText("")
            setLink("")
        }
    }


    const selectorOptions = {
        title: 'Select logo',
        description: 'Select the logo that will be used on the printed brochure',
        accept: 'Use this asset',
        select: 'single',
        limitingSearchExpression: "classification.NamePath = Security/Integration/Internal OR (classification.NamePath = Security/Integration/Public AND LatestVersionOfMasterFile.HasPublicUri = true)",
    }

    const tenant = 'biontech-sb1'
    const encodedOptions = window.btoa(unescape(encodeURIComponent(JSON.stringify(selectorOptions))))
    const url = `https://${tenant}.dam.aprimo.com/dam/selectcontent#options=${encodedOptions}`
    const tenantUrl = `https://${tenant}.dam.aprimo.com`

    const handleMessageEvent = (event: any) => {
        // Ensure only messages from the Aprimo Content Selector are handled.
        if (event.origin !== tenantUrl) return
        if (event.data.result === 'cancel') {
            console.log('You have canceled the selector')
        } else {
            setIdUrl(tenantUrl + "/api/core/record/" + event.data.selection.map((selection: any) => selection.id)[0])
        }
    }

    const tokenQuery = useFetcher({
        config: {
            method: "GET",
            url: "/token",
        },
        onSuccess: (token) => {
            console.log("received token ", token)
            setToken(`Bearer ${token.access_token}`)
        },
        onError: (e) => alert(e)
    }, false);
    
    const renditionsQuery = useFetcher({
        config: {
            method: "GET",
            url: idUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'api-version': "1",
                'select-record': 'classifications, masterfilelatestversion',
                'select-FileVersion': 'publicuris',
                'select-recordclassification': 'target'
            }
        },
        onSuccess: (renditions) => {
            setLink(renditions.masterFileLatestVersion.publicUris.items[0].uri)
        },
        onError: (e) => alert(e)
    }, false);
    
    React.useEffect(() => {
        window.addEventListener("message", handleMessageEvent, false)
    }, [])
        
    React.useEffect(() => {
        tokenQuery.fetch()
    }, [])
    
    React.useEffect(() => {
        if (idUrl ? idUrl.length > 0 : false) renditionsQuery.fetch()
    }, [idUrl])


    return (
        <React.Fragment>
            <Menu
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchor)}
                onClose={onClose}
                variant="menu"
                disableAutoFocusItem={false}
            >
                <Box maxHeight={300} pr={2} >
                    <TextField
                        fullWidth
                        size="small"
                        label="Button text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{marginTop: 1, marginLeft: 1}}
                        />
                    <TextField
                        fullWidth
                        size="small"
                        label="Button link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        sx={{marginTop: 1, marginLeft: 1}}
                        />
                        <ButtonMUI
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={insertString}
                                disabled={text === "" || link === ""}
                                sx={{marginTop: 1, marginLeft: 1}}
                            >
                                Add
                        </ButtonMUI>
                        <ButtonMUI
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => window.open(url, 'selector')}
                                //disabled={text === "" || link === ""}
                                sx={{marginTop: 1, marginLeft: 1}}
                            >
                                Aprimo
                        </ButtonMUI>
                </Box>
            </Menu>
        </React.Fragment>
    )
}
