import React from 'react';

export function State<T = string | boolean | number>(initialState: T) {
    const [state, setState] = React.useState<T>(initialState);

    return {
        get value () {
            return state;
        },
        set value (newValue: T) {
            setState(newValue)
        }
    };
}

export function useState<T>(initialState: T) {
    const stateReference = React.useRef<T>();
    const [state, setState] = React.useState<T>(initialState);
    const [prevState, setPrevState] = React.useState<T>(initialState);

    React.useEffect(() => {
        stateReference.current = state;
    }, []);

    React.useEffect(() => {
        stateReference.current = state;
    }, [state]);

    const updateState = (value: {[key:string]:any}) => {
        setPrevState(state);
        setState({...state, ...value});
    }

    return {
        state,
        prevState,
        updateState,
        stateReference
    };
}