import React from 'react';
//@ts-ignore
import BlockSdk from 'blocksdk';
import { useState } from '../helpers/state';
import { Box, Typography, CircularProgress } from '@mui/material';

const sdk = new BlockSdk();

interface IContentBuilderContextValue {
    data?: any,
    user?: any,
    update?: (data: {[key: string]: {}}) => void
}

interface IContentBuilderBlockProviderState {
    data: any | undefined,
    user: any | undefined,
    status: 'init' | 'success' | 'error'
}

const ContentBuilderBlockContext = React.createContext<IContentBuilderContextValue | null>(null);

export const useContentBuilderBlock = () => {
    const ctx = React.useContext(ContentBuilderBlockContext);

    if (!sdk) {
        throw new Error('Block SDK is missing');
    }

    if (!ctx) {
        throw new Error('Content Builder context is missing');
    }

    const updateData = async (data: {}) => {
        return new Promise((resolve, reject) => {
            sdk.setData(data, (result: {}) => {
                resolve(result);
                ctx.update?.call(null, { data: result });
            })
        })
    }

    const updateContent = (data?: string) => {
        return new Promise((resolve, reject) => {
            sdk.setContent(data, () => {
                resolve(null);
            })
        });
    }

    const updatePreview = (data?: string) => {
        return new Promise((resolve, reject) => {
            sdk.setSuperContent(data, () => {
                resolve(null);
            })
        });
    }

    return {
        ...ctx,
        updateData,
        updateContent,
        updatePreview
    }
}

export const ContentBuilderBlock = ({ children }: React.PropsWithChildren<{}>) => {

    const { state: { data, user, status }, updateState } = useState<IContentBuilderBlockProviderState>({
        status: 'init',
        data: undefined,
        user: undefined
    });

    const getData = async (): Promise<unknown> => {
        return new Promise((resolve, reject) => {
            sdk.getData((data: {}) => {
                resolve(data);
            });
        });
    }

    const getUserData = async (): Promise<unknown> => {
        return new Promise((resolve, reject) => {
            console.log("env : ", process.env)
            if (process.env.NODE_ENV === "development") resolve({})
            sdk.getUserData((data: {}) => {
                resolve(data);
            });
        });
    }

    React.useEffect(() => {
        (async () => {
            const data = await getData();
            const user = await getUserData();

            setTimeout(() => {
                updateState({
                    data,
                    user,
                    status: 'success'
                });
            }, 500);
        })()
        .catch(() => updateState({ status: 'error' }))
    }, []);

    return (
        <ContentBuilderBlockContext.Provider value={{
            data,
            user,
            update: updateState
        }}>
            {status === 'init' &&
                <Box mt={2}>
                    <Typography align="center">
                        Connecting to Marketing Cloud
                        <CircularProgress size="0.9rem" style={{ marginLeft: 5 }} />
                    </Typography>
                </Box>}
            {status === 'error' &&
                <Box mt={2}>
                    <Typography align="center">Connection to Marketing Cloud failed!</Typography>
                </Box>}
            {status === 'success' && children}
        </ContentBuilderBlockContext.Provider>
    )
}