import React from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { useFetcher } from '../helpers/fetch';
import { fileExtensions } from "../utils/file-extensions";

interface IAprimoScreenProps {
    srcImage?: string;
    selectedLayout?: string;
    selectedAsset?: string;
    design?: string;
    width?: number;
    altText?: string;
    linkText?: string;
    fileExtension?: string;
    updateState: any;
}

export const Aprimo = ({
    srcImage,
    selectedAsset,
    selectedLayout,
    design,
    width,
    altText, 
    linkText, 
    fileExtension, 
    updateState,
}: IAprimoScreenProps): React.ReactElement => {
    


    const [idUrl, setIdUrl] = React.useState<string>("")
    const [aprimoURL, setAprimoURL] = React.useState<string>("")
    const [token, setToken] = React.useState("")
    const [tenant, setTenant] = React.useState("")

    
    const credentialsQuery = useFetcher({
        config: {
            method: "GET",
            url: "/token",
        },
        onSuccess: (credentials) => {
            console.log("received token ", credentials)
            setToken(`Bearer ${credentials.token.access_token}`)
            setTenant(credentials.tenant)
        },
        onError: (e) => alert(e)
    }, false);
    
    const renditionsQuery = useFetcher({
        config: {
            method: "GET",
            url: idUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token, 
                'api-version': "1",
                'select-record': 'classifications, masterfilelatestversion',
                'select-FileVersion': 'publicuris',
                'select-recordclassification': 'target'
            }
        },
        onSuccess: (renditions) => {
            updateState({
                fileExtension: renditions.masterFileLatestVersion.fileExtension.toLowerCase(),
                srcImage: renditions.masterFileLatestVersion.publicUris.items[0].uri,
            })
        },
        onError: (e) => alert(e)
    }, false);
    
    React.useEffect(() => {
        if (token && tenant) {
            const selectorOptions = {
                title: 'Select logo',
                description: 'Select the logo that will be used on the printed brochure',
                accept: 'Use this asset',
                select: 'single',
                limitingSearchExpression: "classification.NamePath = Security/Integration/Internal OR (classification.NamePath = Security/Integration/Public AND LatestVersionOfMasterFile.HasPublicUri = true)",
            }
        
            const encodedOptions = window.btoa(unescape(encodeURIComponent(JSON.stringify(selectorOptions))))
            setAprimoURL(`https://${tenant}.dam.aprimo.com/dam/selectcontent#options=${encodedOptions}`)
            const tenantUrl = `https://${tenant}.dam.aprimo.com`
    
            const handleMessageEvent = (event: any) => {
                if (event.origin !== tenantUrl) return
                if (event.data.result === 'cancel') {
                    console.log('You have canceled the selector')
                } else {
                    setIdUrl(tenantUrl + "/api/core/record/" + event.data.selection.map((selection: any) => selection.id)[0])
                }
            }
            console.log("tenantUrl ", tenantUrl)
            window.addEventListener("message", handleMessageEvent, false)
        }
    }, [token, tenant])

    React.useEffect(() => {
        credentialsQuery.fetch()
    }, [])

    
    React.useEffect(() => {
        if (idUrl ? idUrl.length > 0 : false) renditionsQuery.fetch()
    }, [idUrl])
        
    return (
        <React.Fragment>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>

                { selectedAsset && selectedLayout && design ? (

                    <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        href={aprimoURL}
                        onClick={() => {
                            window.open(aprimoURL, 'selector')
                        }}
                        target="_blank"
                    >
                        Open Aprimo selector
                    </Button>

                ) : <Alert severity="error">Choose asset and layout to open Aprimo selector</Alert>}

                {srcImage && fileExtensions.images.includes(fileExtension ? fileExtension : "") ? (

                    <>
                        <TextField
                        fullWidth
                        type="number"
                        size="small"
                        label="Image width"
                        value={width}
                        onChange={(e) => updateState({
                            width: e.target.value,
                        })}
                        />

                        <TextField
                        fullWidth
                        size="small"
                        label="Alt text"
                        value={altText}
                        onChange={(e) => updateState({
                            altText: e.target.value,
                        })}
                        />
                    </>

                ) : <></>}

                {srcImage && fileExtensions.documents.includes(fileExtension ? fileExtension : "") ? (
                    <>
                        <TextField
                        fullWidth
                        size="small"
                        label="Link text"
                        value={linkText}
                        onChange={(e) => updateState({
                            linkText: e.target.value,
                        })}
                        />
                    </>
                ) : <></>}

            </div>
        </React.Fragment>)}