import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';

interface IIConfirmDialogProviderOpenProps {
    onSubmit?: () => void;
    onCancel?: () => void;
    component?: React.ReactElement
}

interface IConfirmDialogProviderState {
    isOpen: boolean,
    onSubmit?: () => void;
    onCancel?: () => void;
    component?: React.ReactElement;
}

interface IConfirmContextProps {
    close: () => void;
    open: (props?: IIConfirmDialogProviderOpenProps) => void;
}

export const ConfirmContext = React.createContext<IConfirmContextProps | null>(null);

export const ConfirmDialogProvider = ({ children }: React.PropsWithChildren<{}>) => {

    const [state, setState] = React.useState<IConfirmDialogProviderState>({
        isOpen: false
    });

    const ConfirmDialog = () => {
        const { isOpen, onSubmit, onCancel, component } = state;

        return (
            <Dialog
                keepMounted
                hideBackdrop
                open={isOpen}
                transitionDuration={0}
                onClose={() => setState({ ... state, isOpen: false })}
            >
                <DialogContent style={{ width: 300, minHeight: 100, overflow: 'hidden' }}>
                    {component}
                </DialogContent>
                <DialogActions>
                    <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5}}>
                        <Box p={1} sx={{ width: "100%" }}>
                            <CircularProgress size="1rem" />
                        </Box>
                        <Box style={{ display: "flex" }}>
                            <Button
                                size="small"
                                color="inherit"
                                onClick={() => {
                                    onCancel ? onCancel.call(null) : setState({ isOpen: false })
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                color="warning"
                                variant="contained"
                                style={{ marginLeft: 6 }}
                                onClick={() => onSubmit?.call(null)}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }

    return (
       <React.Fragment>
            <ConfirmDialog />
            <ConfirmContext.Provider value={{
                open: (props) => {
                    setState({
                        ...props,
                        isOpen: true
                    })
                },
                close: () => setState({ isOpen: false })
            }}>
                {children}
            </ConfirmContext.Provider>
       </React.Fragment>
    );
}