import React, { KeyboardEvent } from 'react';
import { IconButton, Typography } from '@mui/material';

import { ContentBlock, Editor } from "react-draft-wysiwyg";
import "../styles/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, ContentState, RichUtils, convertFromHTML, convertToRaw, convertFromRaw } from 'draft-js';

import { Headline } from "./RTE-headline";
import { Subheadline } from './RTE-subheadline';
import { Button } from './RTE-button';
import { Bulletpoint } from './RTE-bulletpoint';


interface IRichTextEditorProps {
    text?: any;
    _editorState?: string;
    onChange: (params: { [key: string]: string | number | boolean | {}}) => void;
}

type RichTextEditor = (props: IRichTextEditorProps) => React.ReactElement;
  

export const RichTextEditor: RichTextEditor = ({ onChange, text, _editorState }) => {

    const [anchorHeadline, setAnchorHeadline] = React.useState<HTMLElement | null>(null);
    const [anchorSubheadline, setAnchorSubheadline] = React.useState<HTMLElement | null>(null);
    const [anchorButton, setAnchorButton] = React.useState<HTMLElement | null>(null);
    const [anchorBulletpoint, setAnchorBulletpoint] = React.useState<HTMLElement | null>(null);

    const [editorState, setEditorState] = React.useState<EditorState>(() => {
        return _editorState 
            ? EditorState.createWithContent(convertFromRaw(JSON.parse(_editorState))) 
            : EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(text).contentBlocks, convertFromHTML(text).entityMap)
                );
    });

    const options = {
        defaultBlockTag: 'td',
        blockStyleFn: (block: ContentBlock) => {
            return {
                style: {
                    color: "#181010"
                }
            }
        },
        entityStyleFn: (entity: any) => {
            const entityType = entity.get('type');

            if (entityType === 'LINK') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                        href: data.url,
                    },
                    style: {
                        color: "#181010", 
                        textDecoration: "underline",
                    }
                };
            }
        }
    };

    function handleReturn(e: KeyboardEvent, editorState: EditorState): boolean {
        const newEditorState = RichUtils.insertSoftNewline(editorState);
          
        if (!newEditorState) {
          return false;
        }
        setEditorState(newEditorState);
        return true;
    }
    
    
    React.useEffect (() => {
        const _html = stateToHTML(
            editorState.getCurrentContent(), options
            );

        onChange({
            RTE: _html,
            _editorState: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        })
    },[editorState])

    return (
        <React.Fragment>
            <Button 
                anchor={anchorButton} 
                onClose={() => setAnchorButton(null)}
                editorState={editorState} 
                setEditorState={(text: EditorState) => {
                    setEditorState(text);
                    setTimeout(() => setAnchorButton(null), 80);
                }} 
            />
            <Headline 
                anchor={anchorHeadline} 
                onClose={() => setAnchorHeadline(null)}
                editorState={editorState} 
                setEditorState={(text: EditorState) => {
                    setEditorState(text);
                    setTimeout(() => setAnchorHeadline(null), 80);
                }} 
            />
            <Subheadline 
                anchor={anchorSubheadline} 
                onClose={() => setAnchorSubheadline(null)}
                editorState={editorState} 
                setEditorState={(text: EditorState) => {
                    setEditorState(text);
                    setTimeout(() => setAnchorSubheadline(null), 80);
                }} 
            />
            <Bulletpoint 
                anchor={anchorBulletpoint} 
                onClose={() => setAnchorBulletpoint(null)}
                editorState={editorState} 
                setEditorState={(text: EditorState) => {
                    setEditorState(text);
                    setTimeout(() => setAnchorBulletpoint(null), 80);
                }} 
            />
            <Editor
                handleReturn={handleReturn}
                placeholder="Text"
                editorState={editorState}
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorStyle={{ lineHeight: "1.5" }}
                onEditorStateChange={(newState) => {
                  setEditorState(newState)
                }}  
                toolbarCustomButtons={[
                    <IconButton 
                    size="small" 
                    aria-label="add"
                    onClick={(e) => setAnchorBulletpoint(e.currentTarget)} 
                    className="rdw-option-wrapper"
                    sx={{
                            border: "1px solid #F1F1F1", 
                            borderRadius: "4px",
                            height: "30px",  
                            padding: "5px",
                            margin: "0 4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            background: "white",
                            textTransform: "capitalize",
                            color: "#000",
                            '&:hover': {
                                background: "white"
                            },
                        }}
                >
                    <Typography>bulletpoint</Typography>
                </IconButton>,
                    <IconButton 
                        size="small" 
                        aria-label="add"
                        onClick={(e) => setAnchorHeadline(e.currentTarget)} 
                        className="rdw-option-wrapper"
                        sx={{
                                border: "1px solid #F1F1F1", 
                                borderRadius: "4px",
                                height: "30px",  
                                padding: "5px",
                                margin: "0 4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                background: "white",
                                textTransform: "capitalize",
                                color: "#000",
                                '&:hover': {
                                    background: "white"
                                },
                            }}
                    >
                        <Typography>headline</Typography>
                    </IconButton>,
                    <IconButton 
                    size="small" 
                    aria-label="add"
                    onClick={(e) => setAnchorSubheadline(e.currentTarget)} 
                    className="rdw-option-wrapper"
                    sx={{
                            border: "1px solid #F1F1F1", 
                            borderRadius: "4px",
                            height: "30px",  
                            padding: "5px",
                            margin: "0 4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            background: "white",
                            textTransform: "capitalize",
                            color: "#000",
                            '&:hover': {
                                background: "white"
                            },
                        }}
                >
                    <Typography>subheadline</Typography>
                </IconButton>,
                         <IconButton 
                         size="small" 
                         aria-label="add"
                         onClick={(e) => setAnchorButton(e.currentTarget)} 
                         className="rdw-option-wrapper"
                         sx={{
                                 border: "1px solid #F1F1F1", 
                                 borderRadius: "4px",
                                 height: "30px",  
                                 padding: "5px",
                                 margin: "0 4px",
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 cursor: "pointer",
                                 background: "white",
                                 textTransform: "capitalize",
                                 color: "#000",
                                 '&:hover': {
                                     background: "white"
                                 },
                             }}
                     >
                         <Typography>button</Typography>
                     </IconButton>

                ]}  
                toolbar={{
                    options: [
                        'inline',
                        'history',
                        'link',
                    ],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                    link: {
                        // This is callback to process the link added by the user. By default library linkify-it is used for the purpose. 
                        // Most likely the linkify-it library is the reason why ampscript driven url cannot be used.
                        // We just implement custom link processor that return data as it is 
                        linkCallback: (obj: any) => obj // {title: <text>,target: <link>,targetOption: <_blank|_self|_parent|_top>}
                    }
                }}
                />
        </React.Fragment>
    )
}