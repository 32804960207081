import React from 'react';

import { Box, Button, Typography, Stack, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

import { ContentState, convertToRaw } from 'draft-js';

import { useContentBuilderBlock } from '../components/content-builder';
import { CodeEditor } from '../components/code-editor';
import { RichTextEditor } from '../components/rich-text-editor';
import { Aprimo } from './aprimo';
import { Tabs } from '../controls/tabs';
import { ConfirmContext } from '../controls/confirm';
import { assetsList } from "../models/assets-list";

import { useState } from '../helpers/state';
import { useFetcher } from '../helpers/fetch';

interface IMainScreenState {
    content: string;
    isWaitingUpdate?: boolean;
    srcImage: string;
    width?: number;
    altText?: string;
    linkText?: string;
    iconColor?: string;
    fileExtension?: string;
    selectedLayout?: string;
    selectedAsset?: string;
    design?: string;
    templateMarkup?: string;
    RTE?: string;
    headline?: string;
    subheadline?: string;
    title?: string;
    subtitle?: string;
    aprimo?: string;
    _editorState?: any;
    button?: string;
    modules?: {[key: string]: string},
}

export const MainScreen = (): React.ReactElement => {

    const {
        data,
        updateData,
        updateContent,
        updatePreview
    } = useContentBuilderBlock();

    const { state: {
        content,
        isWaitingUpdate,
        srcImage,
        width = 117,
        altText,
        linkText,
        iconColor,
        fileExtension,
        selectedLayout,
        selectedAsset,
        design,
        templateMarkup,
        RTE = "",
        title,
        subtitle,
        aprimo,
        _editorState,
        modules,
    }, updateState } = useState<IMainScreenState>(data);

    const defaultState: IMainScreenState = {
        content: "",
        isWaitingUpdate: false,
        srcImage: "",
        width: 117,
        altText: "",
        linkText: "",
        iconColor: "",
        fileExtension: "",
        selectedLayout: "",
        templateMarkup: "",
        selectedAsset: "",
        RTE: "",
        headline: "",
        subheadline: "",
        title: "",
        subtitle: "",
        aprimo: "",
        _editorState: JSON.stringify(convertToRaw(ContentState.createFromText(''))),
        design: "",
        button: "",
    }

    const templateQuery = useFetcher({
        config: {
            method: "POST",
            url: "/templates",
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: `${selectedAsset}_${selectedLayout}`,
                asset: selectedAsset,
                layout: selectedLayout,
                params: {
                    ...modules,
                    aprimo,
                }
            }
        },
        onSuccess: (recievedTemplate) => {
            updateState({
                content: recievedTemplate,
                isWaitingUpdate: false,
            })
            updatePreview(recievedTemplate);
            updateContent(recievedTemplate);
            updateData({
                srcImage,
                content: recievedTemplate,
                isWaitingUpdate: false,
                selectedLayout,
                templateMarkup,
                selectedAsset,
                RTE,
                title,
                subtitle,
                aprimo,
                _editorState,
                design,
                width,
                altText,
                linkText,
                iconColor,
                fileExtension,
                modules
            })
        },
        onError: (e) => alert(e)
    }, false);

    const elementQuery = useFetcher({
        config: {
            method: "POST",
            url: "/elements",
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                asset: selectedAsset,
                layout: selectedLayout,
                modules: assetsList?.[selectedAsset||""]?.[selectedLayout||""]?.modules,
                params: {
                    RTE,
                    title,
                    subtitle,
                    design,
                }
            }
        },
        onSuccess: (modules) => {
            updateState({
                modules: modules,
            })
        },
        onError: (e) => alert(e)
    }, false);
    
    const getContent = () => {
        if (selectedLayout && selectedAsset && assetsList && design) 
        {
            elementQuery.fetch()
        }
    }
    
    React.useEffect(() => {
        if ( isWaitingUpdate && selectedLayout && selectedAsset && assetsList) 
        {
            templateQuery.fetch()
        }
    }, [modules])
    
    React.useEffect(() => {
        if (selectedAsset && selectedLayout) updateState({isWaitingUpdate: true})
    }, [
        width,
        altText,
        linkText,
        iconColor,
        fileExtension,
        selectedLayout,
        selectedAsset,
        RTE,
        title,
        subtitle,
        design,
        aprimo,
    ])
    
    
    const aprimoQuery = useFetcher({
        config: {
            method: "POST",
            url: "/templates",
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: "aprimo",
                asset: selectedAsset,
                layout: selectedLayout,
                params: {
                    design, 
                    fileExtension, 
                    srcImage,
                    width,
                    altText, 
                    text: linkText, 
                    link: srcImage,
                }
            }
        },
        onSuccess: (populatedAprimoAsset) => {
            updateState({
                aprimo: populatedAprimoAsset,
            })
        },
        onError: (e) => alert(e)
    }, false);

    React.useEffect(() => {
        // fetch aprimo asset
        if (selectedAsset && selectedLayout){
            aprimoQuery.fetch()
        }
    }, [
        srcImage,
        width,
        altText, 
        linkText, 
        design, 
        fileExtension, 
        selectedAsset,
        selectedLayout
    ])

    React.useEffect(() => {
        // reset Layout if Asset changes
        updateState({selectedLayout: null})
    }, [selectedAsset])


    return (
        <Box p={2}>
            <Box mt={2} pb={1}>
                <Tabs items={[
                    {
                        label: "Edit",
                        component: (
                        
                            <React.Fragment>
                                <div style={{display: "flex", flexDirection: "column", gap: 10}}>

                                    <FormControl fullWidth>    
                                    <InputLabel>Select asset</InputLabel>
                                    <Select
                                        sx={{ textTransform: "capitalize" }}
                                        value={selectedAsset || ""}
                                        label="Select template"
                                        labelId="123"
                                        onChange={(e) => updateState({
                                            selectedAsset: e.target.value
                                        })}
                                        IconComponent={() => null}
                                        >
                                        {Object.keys(assetsList)?.map((assetItem: any) => (
                                            <MenuItem
                                            sx={{ textTransform: "capitalize" }}
                                            value={assetItem}
                                            >
                                            {assetItem}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                
                                    { selectedAsset ? (
                                    <FormControl fullWidth>    
                                    <InputLabel>Select layout</InputLabel>
                                    <Select
                                        sx={{ textTransform: "capitalize" }}
                                        value={selectedLayout || ""}
                                        label="Select layout"
                                        labelId="123"
                                        onChange={(e) => updateState({
                                            selectedLayout: e.target.value
                                        })}
                                        IconComponent={() => null}
                                        >
                                        {Object.keys(assetsList[selectedAsset])?.map((layoutItem) => (
                                            <MenuItem
                                            sx={{ textTransform: "capitalize" }}
                                            value={layoutItem}
                                            >
                                            {layoutItem}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                    ) : <></>
                                 }

                                { selectedAsset && selectedLayout ? (
                                    <FormControl fullWidth>    
                                    <InputLabel>Select design</InputLabel>
                                    <Select
                                        sx={{ textTransform: "capitalize" }}
                                        value={design || ""}
                                        label="Select design"
                                        labelId="123"
                                        onChange={(e) => updateState({
                                            design: e.target.value,
                                        })}
                                        IconComponent={() => null}
                                        >
                            
                                            <MenuItem
                                            sx={{ textTransform: "capitalize" }}
                                            value="comirnaty"
                                            >
                                                Comirnaty
                                            </MenuItem>
                                            <MenuItem
                                            sx={{ textTransform: "capitalize" }}
                                            value="bnt"
                                            >
                                                BioNTech
                                            </MenuItem>
                                        
                                        </Select>
                                        </FormControl>
                                        ) : <></>
                                    }

                                    {selectedAsset && selectedLayout && design && assetsList[selectedAsset][selectedLayout]?.modules.includes("title") ? (
                                        <TextField
                                        fullWidth
                                        size="small"
                                        label="Title"
                                        value={title}
                                        onChange={(e) => updateState({
                                            title: e.target.value
                                        })}
                                        />
                                    ) : <></>}

                                        {selectedAsset && selectedLayout && design && assetsList[selectedAsset][selectedLayout]?.modules.includes("subtitle") ? (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Subtitle"
                                            value={subtitle}
                                            onChange={(e) => updateState({
                                                subtitle: e.target.value
                                            })}
                                            />
                                        ) : <></>}

                                    {selectedAsset && selectedLayout && design && assetsList[selectedAsset][selectedLayout]?.modules.includes("RTE") ? (
                                        <RichTextEditor 
                                            text={RTE}
                                            _editorState={_editorState}
                                            onChange={updateState}  
                                            />
                                    ) : <></>}

                                </div>

                                <React.Fragment>
                                    <Box position="fixed" pl={2} pr={2} pt={1} pb={1} sx={{ bottom: 0, left: 0, right: 0, background: "white", zIndex: 100 }}>
                                        <Stack direction="row" justifyContent="flex-end" >
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                                disabled={!isWaitingUpdate}
                                                onClick={getContent}
                                            >
                                                Update
                                            </Button>
                                            <ConfirmContext.Consumer>
                                                {confirm => (
                                                    <Button
                                                        size="small"
                                                        color="secondary"
                                                        variant="outlined"
                                                        sx={{ marginLeft: 1 }}
                                                        onClick={() => confirm?.open({
                                                            component: (
                                                                <Box mt={1.55}>
                                                                    <Typography align="center">Are you sure to return the block all params to initial values?</Typography>
                                                                </Box>
                                                            ),
                                                            onSubmit: () =>  {
                                                                confirm.close();
                                                                
                                                                updateState(defaultState);
                                                                updateData(defaultState)
                                                            }
                                                        })}
                                                    >
                                                        Reset
                                                    </Button>
                                                )}
                                            </ConfirmContext.Consumer>
                                        </Stack>
                                    </Box>
                                </React.Fragment>
                            </React.Fragment>
                        )
                    },
                    {
                        label: "Aprimo",
                        component: (
                            <>
                            <Aprimo 
                            selectedAsset={selectedAsset}
                            selectedLayout={selectedLayout}
                            srcImage={srcImage}
                            width={width} 
                            altText={altText} 
                            linkText={linkText} 
                            design={design} 
                            fileExtension={fileExtension} 
                            updateState={updateState}
                            />

                            <Box position="fixed" pl={2} pr={2} pt={1} pb={1} sx={{ bottom: 0, left: 0, right: 0, background: "white" }}>
                                <Stack direction="row" justifyContent="flex-end" >
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        disabled={!isWaitingUpdate}
                                        onClick={getContent}
                                    >
                                        Update
                                    </Button>
                                </Stack>
                            </Box>
                            </>
                        )
                    },
                    {
                        label: "Code View",
                        component: (
                            <div style={{ width: "100%" }}>
                                <CodeEditor value={content} isEditable={false} />
                            </div>
                        )
                    },
                ]} />
            </Box>
        </Box>
    )
}
